<template>
  <div class="home">
    <iframe id="iframe" src="https://blog.csdn.net/weixin_44320032/article/details/123871522" style="height: calc(100% - 50px)" width="100%" frameborder="0"></iframe>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  }
}
</script>
