<template>
  <div id="app">
    <iframe id="iframeId" class="iframe" src="/csb" width="100%" frameborder="0" @load="loadFrameFn"></iframe>
  </div>
</template>

<script>

export default {
	name: "Home",
	data() {
    return{}
	},

  methods:{

    loadFrameFn() {
       // 获取iframe节点
       const iframeBox = document.getElementById('iframeId')
        // 获取iframe html文件
        const doc = iframeBox.contentWindow.document
        // 获取iframe html文件head
        const head = doc.getElementsByTagName('head')
        // 新建link标签
        const linkTag = document.createElement('link')
        // 设置link标签id
        linkTag.id = 'newstyle'
        // link标签引入css文件的地址 ；window.STATIC_URL 是全局变量，指向静态资源目录，需要自己指定
        linkTag.href = `/new.css?v=1.0`
        // 设置link标签属性
        linkTag.setAttribute('rel', 'stylesheet')
        // 设置link标签属性
        linkTag.setAttribute('type', 'text/css')
        // 将link标签添加进iframe html文件的head里
        head[0].append(linkTag)
        setTimeout(() => {
          this.$loading().close();
        }, 1000);

    },

  },
  async created(){

  },

	mounted() {
      this.$loading({background:'#fff'})
		window.onbeforeunload = function (e) {
			e = e || window.event;
			// 兼容IE8和Firefox 4之前的版本
			if (e) {
				e.returnValue = '刷新提示';
			}
			// Chrome, Safari, Firefox 4+, Opera 12+ , IE 9+
			return '刷新提示';
		};
	},

}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.iframe{
  width: 100%;
  height: 100vh;
  
  .logo{
    display: none;
  }
}
</style>
